@font-face {
  font-family: "sf-pro";
  src: url("assets/fonts/sf-pro-display-regular.woff2");
}
@font-face {
  font-family: "sf-pro";
  src: url("assets/fonts/sf-pro-display-bold.woff2");
  font-weight: bold;
}

@font-face {
  font-family: "sd-gothic";
  src: url("assets/fonts/AppleSDGothicNeoR.woff2");
}
@font-face {
  font-family: "sd-gothic";
  src: url("assets/fonts/AppleSDGothicNeoB.woff2");
  font-weight: bold;
}

@font-face {
  font-family: "bm-hanna";
  src: url("assets/fonts/BMHANNAProOTF.woff2");
}

* {
  font-family: "sf-pro", "sd-gothic";
}

body {
  margin: 0;
}
